<template>
  <div class="welcome">
      <link href="https://fonts.googleapis.com/css?family=Waiting+for+the+Sunrise" rel="stylesheet" type="text/css"/>

    
        <div v-if="!play">
      <h3 v-html="displayMessage"></h3>
      <button v-show="this.message.length == this.displayMessage.length" v-on:click="lucky()">I feel lucky!</button>
        </div>

        <div v-show="play">
        <video id="vid" width="800" height="400" controls loop>
        <source src="../assets/play.mp4" type="video/mp4">
        Your browser does not support the video tag.
        </video>
        
        <button style="margin-top: 50px;" v-on:click="$router.push('jigsaw')">Let's play ></button>
        </div>

  </div>
</template>

<script>
// @ is an alias to /src
//import Notification from '@/components/Notification.vue'

export default {
  name: 'Welcome',
  components: {
    //Notification
  },
  data: function () {
    return {
      message: "Oh my god... Wat hadden die sint en piet een pret, toen ze gingen bedenken, wat voor grap ze " + this.$root.user.name.split(" ")[0] + " moesten schenken ********** Ze bleven grappen en grollen... en dachten aan confetti en nep drollen ********** Opeens kreeg piet een ingeving... wat is een cadeautje nou zonder een beetje uitdaging en spanning!",
      displayMessage: "",
      counter: 0,
      play: false,
    }
  },
  methods: {
    lucky: function() {
        this.play = true;
        document.getElementById('vid').play();
    }
  },

  beforeCreate() {
    if(this.$root.user == null) {
      this.$root.user = {
        name: "Stranger",
        email: "stranger@tnxto.com"
      };
    }
  },

  mounted() {

    


    setInterval(() => {
      //console.log(this.shareScreen.message);
      if(this.displayMessage.length < this.message.length) {
          if(this.message[this.counter] == "*") {
              console.log("enters");
              this.displayMessage += "<br/><br/>";
              this.counter += 10;
          }
          else {
            this.displayMessage += this.message[this.counter];
            this.counter++;
          }
        
      //console.log("x");
      }
    }, 50);
    
  }
}
</script>

<style scoped>
    h3 {
        font-family: 'Waiting for the Sunrise', cursive; 
        font-size: 25px; 
        margin: 10px 50px; 
        letter-spacing: 6px; 
        font-weight: bold;
    }
</style>
