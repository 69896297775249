<template>
  <div class="jigsaw">
      <div class="jigsaw-controls">
          <h1>Jigsaw puzzle!</h1>
            <span>Level: {{game.level}}/{{game.levels}}</span>
            <br/>
            <span>Score: {{game.score}}</span>
            <br/>
            <p>
                Soms moet je je focussen op de dingen waar je goed in bent. In plaats van een mooi gedicht, een nog mooier spel! Veel plezier, ik ben benieuwd hoe goed je jouw collega's (her)kent :)
            </p>

            <h3 style="margin-bottom:0px; margin-top:30px;">Let's play... wie is het?</h3>
            <div style="padding: 0px;margin-top:10px;">
            <button style="border: 1px solid #F38337;margin: 1px;" v-for="option in game.options" v-bind:key="option" v-on:click="clickedOption(option)">{{option.name.split(" ")[0]}}</button>
            </div>
      </div>
      <div class="jigsaw-canvas">
          <canvas id="canvas" width=300 height=300></canvas>
      </div>
      
      
        <br/>
              
  </div>
</template>

<script>
// @ is an alias to /src
//import Notification from '@/components/Notification.vue'

export default {
  name: 'Jigsaw',
  components: {
    //Notification
  },
  data: function () {
    return {
        game: {
            selected: null,
            options: [],
            level: 1,
            levels: 10,
            score: 0,
            continuePlaying: false
        }
    }
  },
  methods: {
      loadGame: function(level = 1) {
        this.game.selected = null;
        this.game.options = [];

        var img=new Image();
        img.onload=function() {
            start(img, level+4);
        };
        var imgIndex = Math.floor(Math.random() * this.$root.colleagues.length);
        this.game.selected = this.$root.colleagues[imgIndex];
        img.src=this.game.selected.img;

        this.game.options.push(this.game.selected);
        while(this.game.options.length < 3) {
            var col = this.$root.colleagues[Math.floor(Math.random() * this.$root.colleagues.length)];
            if(!this.game.options.includes(col)) this.game.options.push(col);
        }
        // this.game.options.push();
        // this.game.options.push(this.$root.colleagues[Math.floor(Math.random() * this.$root.colleagues.length)]);
      },

      clickedOption: function(selected) {
          if(selected == this.game.selected) {
              //alert("Good job!!")
              this.game.score += this.game.level;
          }
          else {
              //alert("Failed, it was: " + this.game.selected.name)
              this.game.score -= this.game.level;
          }
            if(this.game.score < 0) this.game.score = 0;

            if(this.game.level < this.game.levels) {
                this.game.level ++;
                this.loadGame(this.game.level);
            }
            else if(this.game.level == this.game.levels) {
                this.game.continuePlaying = confirm("Dat zijn " + this.game.score + " punten! Ben je het zat of speel je verder?");
                if(this.game.continuePlaying) {
                    this.game.levels += 10;
                    this.game.level ++;
                    this.loadGame(this.game.level);
                }
                else {
                    this.$router.push("thank-you")
                }
                //if(this.game.continuePlaying == false) this.$router.push("memory");
            
            }

            // else if(this.game.level > 10 && this.game.continuePlaying && this.isInt(this.game.level/10) ) {
            //     this.game.continuePlaying = !confirm("Speel je nog steeds?");
            //     if(this.game.continuePlaying == false) this.$router.push("memory");
            // }
            // else {
            //     this.game.continuePlaying = !confirm("Dat zijn " + this.game.score + " punten! Je gaat nu naar het volgende level, klik op cancel om verder te spelen.");
            //     if(this.game.continuePlaying == false) this.$router.push("memory");
            // }
            
      },
      isInt: function(value) {
        var x;
        if (isNaN(value)) {
            return false;
        }
        x = parseFloat(value);
        return (x | 0) === x;
        }
  },
  mounted() {
      this.loadGame(this.game.level)
  }
}

function shuffle(a){
  for(var j, x, i = a.length; i; j = Math.floor(Math.random() * i), x = a[--i], a[i] = a[j], a[j] = x);
  return a;
}

function start(img, level){

    var canvas=document.getElementById("canvas");
    var ctx=canvas.getContext("2d");

    var rows=level;
    var cols=level;

    var iw=canvas.width=img.width;
    var ih=canvas.height=img.height;
    var pieceWidth=iw/cols;
    var pieceHeight=ih/rows;

    var pieces = [
    ]

    for(var c = 0; c < cols; c++) {
        for(var r = 0; r < rows; r++) {
            pieces.push({col:c, row:r});
        }
    }

    shuffle(pieces);

    var i=0;
    for(var y=0;y<rows;y++){
        for(var x=0;x<cols;x++){
            var p=pieces[i++];
            ctx.drawImage(
            // from the original image
            img,
            // take the next x,y piece
            x*pieceWidth, y*pieceHeight, pieceWidth, pieceHeight,
            // draw it on canvas based on the shuffled pieces[] array
            p.col*pieceWidth, p.row*pieceHeight, pieceWidth, pieceHeight
        );
    }}
}
</script>

<style>
    .container {
        display: block;
        width: 60%;
        min-width: 620px;
    }
    #canvas{border:1px solid red;}

    .jigsaw-controls {
        width: 50%;
        float:left;
    }
    .jigsaw-canvas {
        width: 50%;
        float: left;
    }
</style>
